<template>
    <div class="sub-nav">
        <el-menu :style="cssList(first)" :default-active="secound" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <template v-for="item in subNavList[first]">
            <el-menu-item v-if="!item.subtitle" :key="item.id" :index="item.id">{{ item.title }}</el-menu-item>
            <el-submenu v-if="item.subtitle" :key="item.id" :index="item.id">
                <template slot="title">{{ item.title }}</template>
                <el-menu-item v-for="i in item.subtitle" :key="i.id" :index="i.id">{{ i.title }}</el-menu-item>
            </el-submenu>
            </template>
        </el-menu>
    </div>
  </template>
  
  <script>
  export default {
    components: {
    },
    mounted () {
        var path = this.$router.currentRoute.path
        var ary = path.split('/')
        this.first = ary[1]
        this.secound = ary[2]
    },
    data () {
        return {
          first: '',
          secound: '',
          subNavList: {
            introduction: [{
                id: '1',
                title: '公司简介'
            },
            {
                id: '2',
                title: '组织架构'
            },
            {
                id: '3',
                title: '领导团队'
            },
            {
                id: '4',
                title: '人才队伍'
            },
            {
                id: '5',
                title: '企业资质'
            },
            {
                id: '6',
                title: '经营范围'
            },
            {
                id: '7',
                title: '业务清单'
            }],
            news: [{
                id: '1',
                title: '公司新闻',
                path: 'news'
                },
            {
                id: '2',
                title: '行业新闻',
                path: 'news'
            }],
            team: [{
                id: '1',
                title: '团队架构'
            },{
                id: '2',
                title: '高新分院',
                subtitle: [{
                    id: '2-1',
                    title: '高新分院简介'
                }, {
                    id: '2-2',
                    title: '总体规划所'
                },
                {
                    id: '2-3',
                    title: '国土研究所'
                },
                {
                    id: '2-4',
                    title: '工程咨询所'
                }]
            },
            {
                id: '3',
                title: '数字规划研究分院',
                subtitle: [{
                    id: '3-1',
                    title: '数字规划研究分院简介'
                }, {
                    id: '3-2',
                    title: '信息研究所'
                },
                {
                    id: '3-3',
                    title: '详细规划三所'
                },
                {
                    id: '3-4',
                    title: '专项规划二所'
                },
                {
                    id: '3-5',
                    title: '国土利用所'
                }]
            },
            {
                id: '4',
                title: '城市更新分院',
                subtitle: [{
                    id: '4-1',
                    title: '城市更新分院简介'
                }, {
                    id: '4-2',
                    title: '城市设计所'
                }]
            },
            {
                id: '5',
                title: '其他生产部门',
                subtitle: [{
                    id: '5-1',
                    title: '综合规划所'
                },
                {
                    id: '5-2',
                    title: '市政规划一所'
                },
                {
                    id: '5-3',
                    title: '市政规划二所'
                },
                {
                    id: '5-4',
                    title: '详细规划一所'
                },
                {
                    id: '5-5',
                    title: '详细规划二所'
                },
                {
                    id: '5-6',
                    title: '专项规划一所'
                },
                {
                    id: '5-7',
                    title: '测绘一所'
                },
                {
                    id: '5-8',
                    title: '测绘二所'
                },
                {
                    id: '5-9',
                    title: '国土调查所'
                },
                {
                    id: '5-10',
                    title: '交通规划所'
                }]
            },
            {
                id: '6',
                title: '职能部门',
                subtitle: [{
                    id: '6-1',
                    title: '综合管理部'
                }, {
                    id: '6-2',
                    title: '技术经营部'
                }]
            },
            {
                id: '7',
                title: '工程咨询中心'
            },
            {
                id: '8',
                title: '交通设计研究院'
            }],
            achivement: [{
                id: '1',
                title: '主要荣誉'
            },
            {
                id: '2',
                title: '主要业绩'
            },
            {
                id: '3',
                title: '成果宣传'
            },
            {
                id: '4',
                title: '优秀论文'
            },
            {
                id: '5',
                title: '科技创新'
            }],
            partyaffairs: [{
                id: '1',
                title: '党建专栏'
            }],
            recruit: [{
                id: '1',
                title: '人才招聘'
            }],
            contact: [{
                id: '1',
                title: '联系我们'
            }]
        }
        }
    },
    methods: {
        handleSelect (e) {
            this.$router.push('/' + this.first + '/' + e).catch(() => {})
        },
        cssList (val) {
            if (val) {
                var [element] = this.$parent.$refs.navbar.$refs[val]
                var rect = element.getBoundingClientRect()
                var distanceToLeft = rect.left
                var list = {
                    'introduction': distanceToLeft + 'px',
                    'news': distanceToLeft + 'px',
                    'team': 'calc(50% - 500px)',
                    'achivement': distanceToLeft + 'px',
                    'partyaffairs': distanceToLeft + 'px',
                    'recruit': distanceToLeft + 'px',
                    'contact': distanceToLeft + 'px'
                }
                return {
                    'margin-left': list[val]
                }
            }
        }
    }
  }
  </script>
  
<style>
  .sub-nav {
    position: absolute;
    top: 130px;
    width: 100%;
    cursor: pointer;
    line-height: 54px;
    font-size: 22px;
    background-color: #0b1660;
    color: #fff;
    border-top: 1px dashed #f0f0f0;
  }
    .el-menu-demo {
        background-color: #0b1660;
        color: #fff;
    }
    .el-menu-demo.el-menu.el-menu--horizontal {
        border-bottom: none;
    }
    .el-menu-demo.el-menu--horizontal>.el-menu-item:hover {
        color: #fff;
        background-color: rgba(255, 255, 255, .2);
    }
    .el-menu-demo.el-menu--horizontal>.el-menu-item:focus {
        color: #fff;
        background-color: rgba(255, 255, 255, .2);
    }
    .el-menu-demo.el-menu--horizontal>.el-menu-item {
        color: #fff;
        height: 54px;
        line-height: 54px;
    }
    .el-menu-demo.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
        color: #fff;
        background-color: rgba(255, 255, 255, .2);
    }
    .el-menu-demo.el-menu--horizontal>.el-submenu .el-submenu__title:focus {
        color: #fff;
        background-color: rgba(255, 255, 255, .2);
    }
    .el-menu-demo.el-menu--horizontal>.el-submenu .el-submenu__title {
        color: #fff;
        height: 54px;
        line-height: 54px;
    }
    .el-menu-demo.el-menu--horizontal>.el-menu-item.is-active {
        color: #fff;
        border-bottom: 2px solid #fff;
    }
    .el-menu-demo.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
        color: #fff;
        border-bottom: 2px solid #fff;
    }
    .el-menu--popup.el-menu--popup-bottom-start {
        background-color: rgba(255, 255, 255, .9);
    }
    .el-menu--popup.el-menu--popup-bottom-start>.el-menu-item {
        background-color: transparent;
        color: #0b1660;
    }
    .el-menu--popup.el-menu--popup-bottom-start>.el-menu-item:hover {
        color: #3370ff;
    }
  </style>